/* Link Button */
.linkBtn {
  display: inline-flex;
  font-size: 1.2vw;
  align-items: center;
  font-weight: 500;
  position: relative;
  color: var(--text-head);
  font-family: "Aeonik";
  gap: 0.2vw;
}

.linkBtn:hover {
  color: var(--primary);
}

.linkBtn .btnText {
  width: 100%;
  position: relative;
  transition: color .75s cubic-bezier(.19,1,.22,1);
}

.btnText:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  background: var(--text-head);
  transition: all .75s cubic-bezier(.19,1,.22,1);
}

.linkBtn:hover .btnText:after {
  transform: scaleX(1);
  background: var(--primary);
}

.linkBtn .btnImages {
  width: 2vw;
  overflow: hidden;
}
.linkBtn .btnImages div {
  display: inline-flex;
  gap: 6px;
  width: 200%;
  transition: var(--transition-2);
  transform: translateX(-45%);
}
.linkBtn:hover .btnImages .div {
  transform: translateX(5%);
}
.linkBtn .img {
  width: 1.2vw;
}

@media screen and (max-width: 1024px) {
  .linkBtn {
    font-size: 3.5vw;
  }
  .linkBtn .btnImages {
    width: 5vw;
  }
  .linkBtn .img {
    width: 5vw;
  }
}

@media screen and (max-width: 540px) {
  .linkBtn {
    font-size: 4.2vw;
  }
  .linkBtn .btnImages {
    width: 7vw;
  }
  .linkBtn .img {
    width: 4vw;
  }
}
